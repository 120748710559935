<!-- eslint-disable -->
<template>
  <section class="syncInterval">
    <div class="syncInterval__inner">
      <div class="syncInterval__inner__header">
        <img
          class="syncInterval__inner__header__logo"
          src="/img/shopifyAuthorization/site.svg"
          alt="image"
        />
        <span class="syncInterval__inner__header__title"
          >{{ $route.query.shopname }}.myshopify.com</span
        >
      </div>

      <p class="syncInterval__inner__title">Select sync interval</p>
      <p class="syncInterval__inner__text">
        Name your source and choose the sync interval that Shopify data will be
        synced with Intempt.
      </p>

      <div class="syncInterval__inner__row1">
        <p class="syncInterval__inner__row1__title">Sync start date</p>
        <div class="syncInterval__inner__row1__inner">
          <v-menu
            class="syncInterval__inner__row1__inner__menu"
            ref="dateRangeMenu"
            v-model="showCalendar"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="syncInterval__inner__row1__inner__menu__field"
                style="
                  color: #4f5a6f;
                  font-family: Muli, sans-serif;
                  font-size: 14px;
                  font-weight: 400;
                "
                v-model="selectedDate"
                dense
                :rules="[rules.required]"
                background-color="white"
                label="Start Date"
                height="36"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              class="syncInterval__inner__row1__inner__menu__datePicker"
              v-model="selectedDate"
              color="primary"
              type="date"
              width="300"
              height="36"
              :allowed-dates="allowedDates"
              @change="onSelectedDateChange"
              show-adjacent-months
              scrollable
            >
              <v-spacer />

              <v-btn
                class="
                  syncInterval__inner__row1__inner__menu__datePicker__button
                  text-capitalize
                "
                text
                color="primary"
                @click="showCalendar = false"
              >
                Cancel
              </v-btn>
              <v-btn
                class="
                  syncInterval__inner__row1__inner__menu__datePicker__button
                  text-capitalize
                "
                text
                color="primary"
                @click="$refs.dateRangeMenu.save(selectedDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="syncInterval__inner__row2">
        <p class="syncInterval__inner__row2__title">Sync interval</p>
        <div class="syncInterval__inner__row2__inner">
          <span
            class="syncInterval__inner__row2__inner__text"
            style="font-weight: 800"
            >Every</span
          >
          <div class="syncInterval__inner__row2__inner__inline">
            <v-text-field
              class="syncInterval__inner__row2__inner__inline__field"
              type="number"
              v-model.number="hourValue"
              :rules="[rules.onlyNumber, rules.noNegative]"
              background-color="white"
              outlined
              min="0"
              :max="minuteValue > '0' ? '23' : '24'"
              height="36"
              hint="number"
              placeholder="Set value"
              dense
            />
          </div>
          <span class="syncInterval__inner__row2__inner__text">hours</span>
          <div class="syncInterval__inner__row2__inner__inline">
            <v-text-field
              class="syncInterval__inner__row2__inner__inline__field"
              type="number"
              v-model.number="minuteValue"
              :rules="[rules.onlyNumber, rules.noNegative]"
              background-color="white"
              outlined
              min="0"
              :max="hourValue > '23' ? '0' : '59'"
              height="36"
              hint="number"
              placeholder="Set value"
              dense
            />
          </div>
          <span class="syncInterval__inner__row2__inner__text">minutes</span>
        </div>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="syncInterval__bottom">
      <v-btn
        class="syncInterval__bottom__button text-capitalize"
        color="primary"
        @click="$emit('handlePreviousStepClick')"
        outlined
      >
        <img
          class="syncInterval__bottom__button__icon"
          style="margin-right: 15px"
          src="/img/general/long-arrow-left-blue.svg"
          alt="image"
        />
        Previous step
      </v-btn>

      <v-btn
        class="syncInterval__bottom__button text-capitalize"
        color="primary"
        :disabled="!selectedDate || !totalMinutes"
        @click="$emit('handleNextStepClick')"
      >
        Next step
        <img
          class="syncInterval__bottom__button__icon"
          style="margin-left: 15px"
          src="/img/general/long-arrow-right-white.svg"
          alt="image"
        />
      </v-btn>
    </div>
    <!-- BUTTONS -->
  </section>
</template>


<script>
/* eslint-disable */
import rules from "../../../../utils/rules";
import dayjs from "dayjs";

export default {
  name: "SyncInterval",
  props: {},
  components: {},
  data() {
    return {
      rules,
      showCalendar: false,
      selectedDate: "",
      hourValue: 0,
      minuteValue: 0,
    };
  },
  computed: {
    totalMinutes() {
      return this.hourValue * 60 + this.minuteValue;
    },
  },
  methods: {
    onSelectedDateChange(date) {
      this.selectedDate = date;
    },
    allowedDates: (date) =>
      dayjs(date).valueOf() > dayjs(dayjs().subtract(1, "year")).valueOf(),
  },
  mounted() {},
  created() {},
};
</script>


<style scoped lang="scss">
.syncInterval {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;

  .syncInterval__inner {
    background-color: #ffffff;
    border: 1px solid #ced1d9;
    border-radius: 5px;
    width: 600px;
    padding: 30px 50px;
    margin-bottom: 50px;

    .syncInterval__inner__header {
      margin-bottom: 15px;

      .syncInterval__inner__header__logo {
        margin-right: 15px;
      }

      .syncInterval__inner__header__title {
        font-family: Muli, sans-serif;
        color: #137bf2;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .syncInterval__inner__title {
      font-family: Muli, sans-serif;
      color: #282e3b;
      font-weight: 600;
      font-size: 24px;
      margin: 0 0 25px 0;
    }

    .syncInterval__inner__text {
      font-family: Muli, sans-serif;
      color: #4f5a6f;
      font-weight: 400;
      font-size: 14px;
      margin: 0 0 15px 0;
    }
  }
}

::v-deep.syncInterval__bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  .syncInterval__bottom__button {
    font-family: Muli, sans-serif;

    .syncInterval__bottom__button__icon {
      width: 15px;
    }
  }

  .syncInterval__bottom__text {
    margin-left: auto;
    margin-right: 25px;
    cursor: pointer;
    color: #137bf2;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 600;
  }
}

::v-deep.syncInterval__inner__row1 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300px;
  margin-bottom: 25px;

  .syncInterval__inner__row1__title {
    color: #033d80;
    font-family: Muli, sans-serif;
    font-size: 12px;
    font-weight: 600;
  }

  .syncInterval__inner__row1__inner {
    width: 100%;

    .syncInterval__inner__row1__inner__field {
      color: #4f5a6f;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

::v-deep.syncInterval__inner__row2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .syncInterval__inner__row2__title {
    color: #033d80;
    font-family: Muli, sans-serif;
    font-size: 12px;
    font-weight: 600;
  }

  .syncInterval__inner__row2__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .syncInterval__inner__row2__inner__text {
      color: #4f5a6f;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin-right: 10px;
      padding-top: 5px;
    }

    .syncInterval__inner__row2__inner__inline {
      width: 115px;
      margin-right: 10px;

      .syncInterval__inner__row2__inner__field {
        color: #4f5a6f;
        font-family: Muli, sans-serif;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
