<!-- eslint-disable -->
<template>
  <section class="shopify">
    <section
      v-if="auth.isAuthenticated && auth.user && auth.user.email_verified"
    >
      <!-- HEADER -->
      <div class="shopify__header">
        <img
          class="shopify__header__logo"
          src="/img/sources/intemptLogo.svg"
          alt="intemptLogo"
        />
      </div>
      <!-- HEADER -->

      <!-- STEPPER -->
      <v-stepper
        class="shopify__stepper"
        style="box-shadow: none; background: transparent"
        v-model="activeStep"
      >
        <!-- STEPS -->
        <v-stepper-header
          class="shopify__stepper__header"
          v-if="activeStep > 1"
        >
          <v-stepper-step class="shopify__stepper__header__step" :step="1">
          </v-stepper-step>
          <v-stepper-step class="shopify__stepper__header__step" :step="2">
          </v-stepper-step>
          <v-stepper-step class="shopify__stepper__header__step" :step="3">
          </v-stepper-step>
          <v-stepper-step class="shopify__stepper__header__step" :step="4">
          </v-stepper-step>
          <v-stepper-step class="shopify__stepper__header__step" :step="5">
          </v-stepper-step>
          <v-stepper-step class="shopify__stepper__header__step" :step="6">
          </v-stepper-step>
        </v-stepper-header>
        <!-- STEPS -->

        <!-- CONTENT -->
        <v-stepper-items class="shopify__stepper__items">
          <!-- 1st STEP - INTRO -->
          <v-stepper-content step="1">
            <WelcomeScreen @handleNextStepClick="activeStep = 2" />
          </v-stepper-content>
          <!-- 1st STEP - INTRO -->

          <!-- 2nd STEP - ORG CREATION / ORG SELECTION -->
          <v-stepper-content step="2">
            <section class="shopify__stepper__items__step2">
              <OrganizationSelection
                :inside-another-page="true"
                @proceedToNextStep="handleProceedToNextStep"
                @changeOrg="changeOrg"
              >
                <template v-slot:shopifySelectOrg>
                  <div class="shopifyInfo">
                    <img
                      class="shopifyInfo__logo"
                      src="/img/shopifyAuthorization/site.svg"
                      alt="image"
                    />
                    <p class="shopifyInfo__title">
                      {{ $route.query.shopname }}.myshopify.com
                    </p>
                  </div>
                  <p class="shopify__stepper__items__step2__title">
                    Select organization
                  </p>
                  <p class="shopify__stepper__items__step2__text">
                    Organizations let you collaborate with team members, add
                    permissions and share sources.
                  </p>
                </template>
                <template v-slot:shopifyCreateOrg>
                  <div class="shopifyInfo">
                    <img class="shopifyInfo__logo" src="" alt="image" />
                    <p class="shopifyInfo__title">
                      {{ $route.query.shopname }}.myshopify.com
                    </p>
                  </div>
                  <p class="shopify__stepper__items__step2__title">
                    Create organization
                  </p>
                  <p class="shopify__stepper__items__step2__text">
                    Organizations let you collaborate with team members, add
                    permissions and share sources.
                  </p>
                </template>
              </OrganizationSelection>
            </section>
          </v-stepper-content>
          <!-- 2nd STEP - ORG CREATION / ORG SELECTION -->

          <!-- 3rd STEP - METRIC TEMPLATES -->
          <v-stepper-content step="3">
            <Templates
              :templates="metricTemplates"
              template-type="metrics"
              :selected-source="selectedSource"
              @handleTemplateSelect="handleTemplateSelect"
              @handlePreviousStepClick="activeStep = 2"
              @handleSkipStepClick="
                activeStep = 4;
                handleSkipTemplateSelection('metrics');
              "
              @handleNextStepClick="activeStep = 4"
            >
              <template v-slot:shopifyHeader>
                <div class="templates__header">
                  <div class="templates__header__shop">
                    <img
                      class="templates__header__shop__logo"
                      src="/img/shopifyAuthorization/site.svg"
                      alt="image"
                    />
                    <span class="templates__header__shop__title"
                      >{{ $route.query.shopname }}.myshopify.com</span
                    >
                  </div>
                  <p class="templates__header__title">
                    Choose metric templates
                  </p>
                  <p class="templates__header__description">
                    Metric templates will accelerate your setup by creating
                    Shopify metrics that you can access after you set up the
                    Intempt Shopify app.
                  </p>
                </div>
              </template>
            </Templates>
          </v-stepper-content>
          <!-- 3rd STEP - METRIC TEMPLATES -->

          <!-- 4th STEP - SEGMENT TEMPLATES -->
          <v-stepper-content step="4">
            <Templates
              :templates="segmentTemplates"
              template-type="segments"
              :selected-source="selectedSource"
              @handleTemplateSelect="handleTemplateSelect"
              @handlePreviousStepClick="activeStep = 3"
              @handleSkipStepClick="
                activeStep = 5;
                handleSkipTemplateSelection('segments');
              "
              @handleNextStepClick="activeStep = 5"
            >
              <template v-slot:shopifyHeader>
                <div class="templates__header">
                  <div class="templates__header__shop">
                    <img
                      class="templates__header__shop__logo"
                      src="/img/shopifyAuthorization/site.svg"
                      alt="image"
                    />
                    <span class="templates__header__shop__title"
                      >{{ $route.query.shopname }}.myshopify.com</span
                    >
                  </div>
                  <p class="templates__header__title">
                    Choose segment templates
                  </p>
                  <p class="templates__header__description">
                    Segment templates will accelerate your setup by creating
                    Shopify segments that you can access after you set up the
                    Intempt Shopify app.
                  </p>
                </div>
              </template>
            </Templates>
          </v-stepper-content>
          <!-- 4th STEP - SEGMENT TEMPLATES -->

          <!-- 5th STEP - SYNC INTERVAL -->
          <v-stepper-content step="5">
            <SyncInterval
              ref="syncInterval"
              @handlePreviousStepClick="activeStep = 4"
              @handleNextStepClick="activeStep = 6"
            />
          </v-stepper-content>
          <!-- 5th STEP - SYNC INTERVAL -->

          <!-- 6th STEP - FINALIZE -->
          <v-stepper-content step="6">
            <FinalScreen
              @handleLaunchClick="handleLaunchClick"
              :loading="loading"
            />
          </v-stepper-content>
          <!-- 6th STEP - FINALIZE -->
        </v-stepper-items>
        <!-- CONTENT -->
      </v-stepper>
    </section>
    <section
      v-if="auth.isAuthenticated && auth.user && !auth.user.email_verified"
    >
      <verificationRequired />
    </section>
    <!-- STEPPER -->
    <section class="loader" v-if="!auth.isAuthenticated">
      <div class="">
        <v-progress-circular
          indeterminate
          width="5"
          size="96"
          color="primary"
        />
      </div>
    </section>
  </section>
</template>


<script>
/* eslint-disable */

import rules from "../../../utils/rules";
import OrganizationSelection from "./components/OrganizationSelection";
import { mapState } from "vuex";
import _ from "lodash";
import slugify from "slugify";
import dayjs from "dayjs";
import WelcomeScreen from "./components/WelcomeScreen";
import FinalScreen from "./components/FinalScreen";
import Templates from "./components/Templates";
import SyncInterval from "./components/SyncInterval";
import verificationRequired from "./components/verificationRequired";
const short = require("short-uuid");
export default {
  name: "shopify",
  props: {},
  components: {
    WelcomeScreen,
    OrganizationSelection,
    FinalScreen,
    Templates,
    SyncInterval,
    verificationRequired,
  },
  data() {
    return {
      rules,
      activeStep: 1,
      allTemplates: [],
      selectedSource: {
        category: "eCommerce",
        type: "eCommerce.shopify",
        title: "Shopify",
        iconPath: "/img/sources/shopifyImg.svg",
        imgPath: "/img/connectors/shopify.svg",
      },
      newSourceObject: {},
      source: null,
      loading: false,
      exists: false,
      org: null,
      activeUser: null,
    };
  },
  computed: {
    ...mapState("auth", {
      token: (state) => state.token,
    }),
    auth() {
      return this.$auth;
    },
    ...mapState("sources", {
      templateList: (state) => state.templateList,
    }),
    integrationId: {
      get: function () {
        return localStorage.getItem("connectorId");
      },
      set: function (newValue) {
        localStorage.setItem("connectorId", newValue);
      },
    },
    hasMetricTemplates() {
      if (Object.keys(this.selectedSource).length > 0) {
        const _templates1 = this.allTemplates.filter((_template) =>
          _template.sourceTypes.includes(this.selectedSource.type)
        );
        const _templates2 = _templates1.filter((_template) =>
          _template.objects.some((obj) => obj.type === "metric")
        );
        return _templates2.length > 0;
      } else {
        return false;
      }
    },
    metricTemplates() {
      if (Object.keys(this.selectedSource).length > 0) {
        const _templates1 = this.allTemplates.filter((_template) =>
          _template.sourceTypes.includes(this.selectedSource.type)
        );
        const _templates2 = _templates1.filter((_template) =>
          _template.objects.some((obj) => obj.type === "metric")
        );
        return _templates2;
        // return [..._templates2, ..._templates2, ..._templates2, ..._templates2, ..._templates2,]; // UI TEST PURPOSE
      } else {
        return [];
      }
    },
    selectedMetricTemplates() {
      if (this.metricTemplates.length > 0) {
        return this.metricTemplates.filter(
          (template) => template.selected === true
        );
      } else {
        return [];
      }
    },
    hasSegmentTemplates() {
      if (Object.keys(this.selectedSource).length > 0) {
        const _templates1 = this.allTemplates.filter((_template) =>
          _template.sourceTypes.includes(this.selectedSource.type)
        );
        const _templates2 = _templates1.filter((_template) =>
          _template.objects.some((obj) => obj.type === "segment")
        );
        return _templates2.length > 0;
      } else {
        return false;
      }
    },
    segmentTemplates() {
      if (Object.keys(this.selectedSource).length > 0) {
        const _templates1 = this.allTemplates.filter((_template) =>
          _template.sourceTypes.includes(this.selectedSource.type)
        );
        const _templates2 = _templates1.filter((_template) =>
          _template.objects.some((obj) => obj.type === "segment")
        );
        return _templates2;
        // return [..._templates2, ..._templates2, ..._templates2, ..._templates2, ..._templates2,]; // UI TEST PURPOSE
      } else {
        return [];
      }
    },
    selectedSegmentTemplates() {
      if (this.segmentTemplates.length > 0) {
        return this.segmentTemplates.filter(
          (template) => template.selected === true
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    token: {
      handler(newValue, oldValue) {
        if (!newValue) return;
        if (newValue) {
        }
      },
    },
  },
  methods: {
    handleTemplateSelect({ template1, templateType }) {
      if (templateType === "segments") {
        const index = this.segmentTemplates.findIndex(
          (template2) => template2.title === template1.title
        );
        this.segmentTemplates[index].selected =
          !this.segmentTemplates[index].selected;
      } else if (templateType === "metrics") {
        const index = this.metricTemplates.findIndex(
          (template2) => template2.title === template1.title
        );
        this.metricTemplates[index].selected =
          !this.metricTemplates[index].selected;
      }
    },
    handleSkipTemplateSelection(templateType) {
      if (templateType === "segments") {
        this.segmentTemplates.forEach(
          (template) => (template.selected = false)
        );
      } else if (templateType === "metrics") {
        this.metricTemplates.forEach((template) => (template.selected = false));
      }
    },
    handleLaunchClick() {
      if (this.exists) {
        return window.open(
          process.env.NODE_ENV === "production"
            ? `https://app.intempt.com/sources/${this.source.id}?from=shopify&sourceId=${this.source.id}&org=${this.org}`
            : `https://app.staging.intempt.com/sources/${this.source.id}?from=shopify&sourceId=${this.source.id}&org=${this.org}`,
          "_self"
        );
      }

      this.loading = true;
      const _syncDate = dayjs(this.$refs.syncInterval.selectedDate).valueOf();
      const _syncInterval =
        this.$refs.syncInterval.hourValue * 60 +
        this.$refs.syncInterval.minuteValue;
      const _connectorTitle = short.generate();
      localStorage.removeItem("connectorId");

      this.$store
        .dispatch("sources/createNewConnector", {
          type: "shopify",
          title: _connectorTitle,
          category: "e-commerce",
          data: {
            shopName: this.$route.query.shopname,
          },
        })
        .then((response) => {
          this.newSourceObject.type = "eCommerce.shopify";
          this.newSourceObject.title = this.$route.query.shopname;
          this.newSourceObject.name = slugify(this.newSourceObject.title);
          this.newSourceObject.integrationId = response;

          this.$store
            .dispatch("sources/createNewSource", {
              sourceObj: this.newSourceObject,
              isOnboarding: true,
            })
            .then((source) => {
              this.integrationId = "";
              this.source = source;
              this.$store.dispatch("sources/createSourceSchedule", {
                sourceId: source.id,
                scheduleObject: {
                  ...(_syncInterval && { interval: _syncInterval }),
                  ...(_syncDate && { fromDate: _syncDate }),
                },
              });

              this.$store.dispatch("sources/createTemplates", {
                sourceId: source.id,
                templates: [
                  ...this.selectedSegmentTemplates,
                  ...this.selectedMetricTemplates,
                ],
              });

              this.newSourceObject = {};
              setTimeout(() => {
                this.$store.dispatch("global/clearAlerts");
              }, 5000);
            })
            .catch((err) => {
              this.loading = false;
            })
            .finally(async () => {
              await this.$store.dispatch("global/clearAlerts");
              this.loading = false;
              window.open(
                process.env.NODE_ENV === "production"
                  ? `https://app.intempt.com/sources/${this.source.id}?from=shopify&sourceId=${this.source.id}&org=${this.org}`
                  : `https://app.staging.intempt.com/sources/${this.source.id}?from=shopify&sourceId=${this.source.id}&org=${this.org}`,
                "_self"
              );
            });
        });
    },
    handleProceedToNextStep() {
      const _sessionId = localStorage.getItem("sessionId");
      console.log(_sessionId);
      this.newSourceObject.title = this.$route.query.shopname;
      this.newSourceObject.name = slugify(this.newSourceObject.title);
      this.$store
        .dispatch(
          "sources/checkSourceNameAvailability",
          slugify(this.newSourceObject.name)
        )
        .then(async (response) => {
          const _sessionId = localStorage.getItem("sessionId");
          if (_sessionId) {
            await this.$store.dispatch(
              "sources/getIntegrationData",
              _sessionId
            );
          }
          if (response.data._embedded.sources.length > 0) {
            this.exists = true;
            this.source = response.data._embedded.sources[0];
            this.activeStep = 6;
          } else {
            this.activeStep = 3;
          }
        })
        .catch(() => {
          this.activeStep = 3;
          const _sessionId = localStorage.getItem("sessionId");
          if (_sessionId) {
            this.$store.dispatch("sources/getIntegrationData", _sessionId);
          }
        });
    },
    changeOrg(org) {
      this.org = org;
    },
  },
  mounted() {
    this.newSourceObject.title = this.$route.query.shopname;
    this.$store
      .dispatch("sources/fetchTemplates")
      .then(() => (this.allTemplates = _.cloneDeep(this.templateList)));
    if (localStorage.getItem("currentSlide") === "2") {
      this.activeStep = 2;
      localStorage.removeItem("currentSlide");
    }
  },
  created() {},
};
</script>


<style scoped lang="scss">
.shopify {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  //overflow-y: scroll;

  .shopify__header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .shopify__header__logo {
      width: auto;
      margin: 50px 0 25px 0;
      height: 50px;
    }
  }

  .v-stepper__wrapper {
    padding: 0;
    background: transparent;
    box-shadow: none;
    height: 100%;
  }
}

::v-deep.shopify__stepper__items {
  height: min-content;
  overflow-y: scroll;
}

::v-deep.v-stepper__items {
  height: min-content;
}

::v-deep.shopify__stepper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: min-content;

  .shopify__stepper__header {
    width: max-content;
    box-shadow: none;
    margin-bottom: 25px;

    .shopify__stepper__header__step {
      padding: 0;
      margin: 5px 15px;
    }
  }
}

::v-deep.theme--light.v-stepper .v-stepper__step__step {
  color: transparent;
}

::v-deep span.v-stepper__step__step {
  margin: 0;
  height: 12px;
  width: 12px;
  min-width: 12px;
}

::v-deep.v-stepper__content {
  height: min-content;
  padding: 0;
}

// STEP 2
::v-deep.shopify__stepper__items__step2 {
  background-color: #ffffff;
  border: 1px solid #ced1d9;
  border-radius: 5px;
  width: max-content;
  padding: 50px;

  .shopifyInfo {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 25px 0;

    .shopifyInfo__logo {
      margin-right: 15px;
    }

    .shopifyInfo__title {
      font-family: Muli, sans-serif;
      color: #137bf2;
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }
  }

  .shopify__stepper__items__step2__title {
    font-family: Muli, sans-serif;
    color: #282e3b;
    font-weight: 600;
    font-size: 24px;
    margin: 0 0 25px 0;
    text-align: left;
    width: 100%;
  }

  .shopify__stepper__items__step2__text {
    font-family: Muli, sans-serif;
    color: #4f5a6f;
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 25px 0;
    text-align: left;
    width: 100%;
  }
}

// STEP 2

.loader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
