export default {
    templateList: [],
    loadingTemplateList: [],
    sourcesList: [],
    selectedSource: null,
    selectedConnector: null,
    selectedSourceHistory: [],
    selectedSourceSchedule: null,
    selectedSourceApiKey: null,
    loadingSourcesList: false,
    sourcesCategorizedList: [
        {
            category: "Web",
            type: "web",
            title: "Web Tracker",
            iconPath: "/img/sources/javascriptImg.svg",
            imgPath: "/img/sources/javascriptImg.svg",
            steps: [
                // {
                //   number: 1,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/intemptLogo.svg',
                //   alt: "Intempt Logo",
                //   title: 'Connect your data stack with Intempt',
                //   description: 'Select a source to get started',
                // },
                {
                    number: 2,
                    height: "height: 75px;",
                    imgPath: "/img/sources/javascriptImg.svg",
                    alt: "Web Tracker",
                    title: "Choose your metric templates",
                    description: "",
                },
                {
                    number: 3,
                    height: "height: 75px;",
                    imgPath: "/img/sources/javascriptImg.svg",
                    alt: "Web Tracker",
                    title: "Choose your segment templates",
                    description: "",
                },
                {
                    number: 4,
                    height: "height: 75px;",
                    imgPath: "/img/sources/javascriptImg.svg",
                    alt: "Web Tracker",
                    title: "Set up the source configuration",
                    description: "",
                },
                // {
                // 	number: 5,
                // 	height: "height: 50px;",
                // 	imgPath: "/img/sources/intemptLogo.svg",
                // 	alt: "Web Tracker",
                // 	title: "Activate products",
                // 	description: "Platform and add-ons are free up to 10.000 MTUs",
                // },
            ],
        },
        {
            category: "Mobile",
            type: "ios",
            title: "iOS SDK",
            iconPath: "/img/sources/iosImg.svg",
            imgPath: "/img/sources/iosImg.svg",
            steps: [
                // {
                //   number: 1,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/intemptLogo.svg',
                //   alt: "Intempt Logo",
                //   title: 'Connect your data stack with Intempt',
                //   description: 'Select a source to get started',
                // },
                {
                    number: 2,
                    height: "height: 75px;",
                    imgPath: "/img/sources/iosImg.svg",
                    alt: "iOS SDK",
                    title: "Choose your metric templates",
                    description: "",
                },
                {
                    number: 3,
                    height: "height: 75px;",
                    imgPath: "/img/sources/iosImg.svg",
                    alt: "iOS SDK",
                    title: "Choose your segment templates",
                    description: "",
                },
                {
                    number: 4,
                    height: "height: 75px;",
                    imgPath: "/img/sources/iosImg.svg",
                    alt: "iOS SDK",
                    title: "Set up the source configuration",
                    description: "",
                },
                // {
                // 	number: 5,
                // 	height: "height: 50px;",
                // 	imgPath: "/img/sources/intemptLogo.svg",
                // 	alt: "iOS SDK",
                // 	title: "Activate products",
                // 	description: "Platform and add-ons are free up to 10.000 MTUs",
                // },
            ],
        },
        {
            category: "Feeds",
            type: "api",
            title: "API",
            iconPath: "/img/sources/apiImg.svg",
            imgPath: "/img/sources/apiImg.svg",
            steps: [
                // {
                //   number: 1,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/intemptLogo.svg',
                //   alt: "Intempt Logo",
                //   title: 'Connect your data stack with Intempt',
                //   description: 'Select a source to get started',
                // },
                // {
                //   number: 3,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/apiImg.svg',
                //   alt: 'API',
                //   title: 'Choose your metric templates',
                //   description: '',
                // },
                // {
                //   number: 4,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/apiImg.svg',
                //   alt: 'API',
                //   title: 'Choose your segment templates',
                //   description: '',
                // },
                {
                    number: 4,
                    height: "height: 75px;",
                    imgPath: "/img/sources/apiImg.svg",
                    alt: "API",
                    title: "Set up the source configuration",
                    description: "",
                },
                // {
                // 	number: 5,
                // 	height: "height: 50px;",
                // 	imgPath: "/img/sources/intemptLogo.svg",
                // 	alt: "API",
                // 	title: "Activate products",
                // 	description: "Platform and add-ons are free up to 10.000 MTUs",
                // },
            ],
        },
        {
            category: "eCommerce",
            type: "eCommerce.shopify",
            title: "Shopify",
            iconPath: "/img/sources/shopifyImg.svg",
            imgPath: "/img/connectors/shopify.svg",
            steps: [
                // {
                //   number: 1,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/intemptLogo.svg',
                //   alt: "Intempt Logo",
                //   title: 'Connect your data stack with Intempt',
                //   description: 'Select a source to get started',
                // },
                {
                    number: 2,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/shopify.svg",
                    alt: "Shopify",
                    title: "Choose your metric templates",
                    description: "",
                },
                {
                    number: 3,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/shopify.svg",
                    alt: "Shopify",
                    title: "Choose your segment templates",
                    description: "",
                },
                {
                    number: 4,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/shopify.svg",
                    alt: "Shopify",
                    title: "Configure the connection",
                    description: "",
                },
                {
                    number: 5,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/shopify.svg",
                    alt: "Shopify",
                    title: "Set up the source configuration",
                    description: "",
                },
                // {
                // 	number: 6,
                // 	height: "height: 50px;",
                // 	imgPath: "/img/sources/intemptLogo.svg",
                // 	alt: "Shopify",
                // 	title: "Activate products",
                // 	description: "Platform and add-ons are free up to 10.000 MTUs",
                // },
            ],
        },
        {
            category: "CRM",
            type: "crm.hubspot",
            title: "Hubspot",
            iconPath: "/img/sources/hubspotImg.svg",
            imgPath: "/img/connectors/hubspot.svg",
            steps: [
                // {
                //   number: 1,
                //   height: 'height: 75px;',
                //   imgPath: '/img/sources/intemptLogo.svg',
                //   alt: "Intempt Logo",
                //   title: 'Connect your data stack with Intempt',
                //   description: 'Select a source to get started',
                // },
                {
                    number: 2,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/hubspot.svg",
                    alt: "Hubspot",
                    title: "Choose your metric templates",
                    description: "",
                },
                {
                    number: 3,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/hubspot.svg",
                    alt: "Hubspot",
                    title: "Choose your segment templates",
                    description: "",
                },
                {
                    number: 4,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/hubspot.svg",
                    alt: "Hubspot",
                    title: "Configure the connection",
                    description: "",
                },
                {
                    number: 5,
                    height: "height: 50px;",
                    imgPath: "/img/connectors/hubspot.svg",
                    alt: "Hubspot",
                    title: "Set up the source configuration",
                    description: "",
                },
                // {
                // 	number: 6,
                // 	height: "height: 50px;",
                // 	imgPath: "/img/sources/intemptLogo.svg",
                // 	alt: "Shopify",
                // 	title: "Activate products",
                // 	description: "Platform and add-ons are free up to 10.000 MTUs",
                // },
            ],
        },
        // {
        //   category: 'Advertising',
        //   type: 'googleads',
        //   title: 'Google Ads',
        //   imgPath: '/img/sources/googleAds.svg',
        //   steps: [1, 2, 3, 4], // ????
        // },
    ],
    sourceTypes: [
        {
            title: "Intempt Web Tracker",
            name: "web",
            description: "Intempt Web Tracker can be used on any website and is really cool",
            icon: "/img/sources/javascriptImg.svg",
        },
        {
            title: "API Source",
            name: "api",
            description: "Use API to connect Intempt to your data source",
            icon: "/img/sources/apiImg.svg",
        },
        {
            title: "iOS App",
            name: "ios",
            description: "Intempt iOS Tracker can be used on any iOS app and is really cool",
            icon: "/img/sources/iosImg.svg",
        },
        // {
        //   title: 'Proximity iOS SDK',
        //   name: 'proximity',
        //   description: 'Intempt Proximity iOS SDK Tracker can be used on any iOS app and is really cool',
        //   icon: '',
        // },
        {
            title: "Shopify",
            name: "eCommerce.shopify",
            description: "Sync your customer, order and product data into Intempt database.",
            icon: "/img/sources/shopifyImg.svg",
        },
        {
            title: "Hubspot",
            name: "crm.hubspot",
            description: "Sync data from Hubspot to create a 360° view of your leads and prospects.",
            icon: "/img/sources/hubspotImg.svg",
        },
    ],
    relatedEventsList: [],
    relatedSegmentsList: [],
    relatedCollectionsList: [],
    relatedMetricsList: [],
    loadingRelatedSegmentsList: false,
    loadingRelatedMetricsList: false,

};