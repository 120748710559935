/* eslint-disable */
import axios from 'axios'
import { getInstance as authWrapper } from '@/auth/authWrapper'
let baseUrl = ''

switch (process.env.NODE_ENV) {
    case 'production':
        baseUrl = 'https://api.intempt.com/v1'
        break
    default:
        baseUrl = 'https://api.staging.intempt.com/v1'
}
const axiosInstance = axios.create({
    baseURL: baseUrl,
})


axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const { response } = error;

        if (response.status === 403) {
            // src/auth/authWrapper.js
            // window.location.reload()
            // authWrapper()
            //   .init(refresh)
            //   .then(() => resolve())
            //   .catch(() => reject())
        }
        return Promise.reject(error);
    }
);

export default axiosInstance
