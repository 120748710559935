<!-- eslint-disable -->
<template>
  <section class="finalScreenContainer">
    <div class="finalScreenContainer__header">
      <img
        class="finalScreenContainer__header__logo"
        src="/img/shopifyAuthorization/site.svg"
        alt="image"
      />
      <span class="finalScreenContainer__header__title"
        >{{ $route.query.shopname }}.myshopify.com</span
      >
    </div>
    <p class="finalScreenContainer__primaryTitle">
      Intempt integration is up and running
    </p>
    <p class="finalScreenContainer__text">
      Get a 360° customer view and automate engagement workflows to acquire,
      engage and retain more customers.
    </p>
    <div class="finalScreenContainer__horizontalLine"></div>
    <p class="finalScreenContainer__secondaryTitle">Data sync status</p>
    <div class="finalScreenContainer__connect">
      <span class="finalScreenContainer__connect__text">Shopify</span>
      <img
        class="finalScreenContainer__connect__icon"
        src="/img/shopifyAuthorization/connect.svg"
        alt="image"
      />
      <span class="finalScreenContainer__connect__text">Intempt</span>
    </div>
    <p class="finalScreenContainer__text">
      The data from Shopify is synced with Intempt based on your specified sync
      intervals This sync is always enabled, as long as the Intempt Shopify App
      is installed.
    </p>

    <div class="finalScreenContainer__button">
      <v-btn
        color="primary"
        width="100%"
        class="text-capitalize"
        :loading="loading"
        :disabled="loading"
        @click="$emit('handleLaunchClick')"
      >
        Launch Intempt Console
      </v-btn>
    </div>
  </section>
</template>


<script>
/* eslint-disable */

export default {
  name: "WelcomeScreen",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>


<style scoped lang="scss">
.finalScreenContainer {
  background-color: #ffffff;
  border: 1px solid #ced1d9;
  border-radius: 5px;
  padding: 30px 50px;
  width: 700px;

  .finalScreenContainer__header {
    margin-bottom: 15px;

    .finalScreenContainer__header__logo {
      margin-right: 15px;
    }

    .finalScreenContainer__header__title {
      font-family: Muli, sans-serif;
      color: #137bf2;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .finalScreenContainer__primaryTitle {
    font-family: Muli, sans-serif;
    color: #282e3b;
    font-weight: 600;
    font-size: 24px;
    margin: 0 0 25px 0;
  }

  .finalScreenContainer__secondaryTitle {
    font-family: Muli, sans-serif;
    color: #282e3b;
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 25px 0;
  }

  .finalScreenContainer__text {
    font-family: Muli, sans-serif;
    color: #4f5a6f;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
  }

  .finalScreenContainer__connect {
    margin-bottom: 25px;
    display: flex;
    align-items: normal;

    .finalScreenContainer__connect__text {
      font-family: Muli, sans-serif;
      color: #4f5a6f;
      font-weight: 600;
      font-size: 14px;
    }

    .finalScreenContainer__connect__icon {
      margin: 0 10px;
    }
  }

  .finalScreenContainer__horizontalLine {
    background: #4f5a6f;
    height: 1px;
    width: 100%;
    margin: 25px 0;
  }

  .finalScreenContainer__button {
    margin-top: 50px;
  }
}
</style>
