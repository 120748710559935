function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

export default {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState("auth.currentUser", newValue || null);
    },
    SET_USER_DETAILS(state, newValue) {
        state.userDetails = newValue;
    },
    SET_TOKEN(state, newValue) {
        state.token = newValue;
    },
};