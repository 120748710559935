<!-- eslint-disable -->
<template>
  <section class="verificationRequired">
    <div class="verificationRequired__inner">
      <img
        class="verificationRequired__inner__icon"
        src="/img/verification_required_icon.png"
        alt="image"
      />
      <p class="verificationRequired__inner__title">Please verify your email</p>
      <p class="verificationRequired__inner__text">You’re almost there!</p>
      <p class="verificationRequired__inner__text">
        We sent an email to verify you email account.
      </p>
      <p class="verificationRequired__inner__text">
        Just click on the link in that email to complete your signup.
      </p>
      <p class="verificationRequired__inner__text">
        If you don’t see it, you may need to check your spam folder.
      </p>
      <!--			<p class="verificationRequired__inner__text">Still can't find the email?</p>-->

      <!--			<v-btn class="text-capitalize mb-3"-->
      <!--			       color="primary"-->
      <!--			       :disabled="emailIsSent"-->
      <!--			       @click="handleReSendEmail">-->
      <!--				Resend Email-->
      <!--			</v-btn>-->

      <p class="verificationRequired__inner__text">
        Need help?
        <span class="link"
          ><a href="mailto:success@intempt.com">Contact Us</a></span
        >
      </p>
    </div>
  </section>
</template>


<script>
/* eslint-disable */

export default {
  name: "verification_required",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleReSendEmail() {},
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.verificationRequired {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .verificationRequired__inner {
    border: solid 1px #f5f6f8;
    background-color: white;
    border-radius: 3px;
    position: relative;
    padding: 50px;
    height: 400px;
    width: 575px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .verificationRequired__inner__icon {
      height: 75px;
      width: auto;
      position: absolute;
      top: -50px;
    }

    .verificationRequired__inner__title {
      font-family: Muli, sans-serif !important;
      font-size: 20px;
      font-weight: 600;
      color: #282e3b;
    }

    .verificationRequired__inner__text {
      font-family: Muli, sans-serif !important;
      font-size: 14px;
      font-weight: 400;
      color: #4f5a6f;

      .link {
        color: #137bf2;
      }
    }
  }
}
</style>
