function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

export default {
    currentUser: getSavedState("auth.currentUser"),
    token: "",
    userDetails: {
        name: "",
        title: "",
        bio: "",
        phone: "",
    },
};