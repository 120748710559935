/* eslint-disable */
export default {
    onlyNumber: value => (!!value || typeof value === 'number') || 'Should be a number',
    onlyPositiveNumber: value => (typeof value === 'number' && value > 0) || 'Should be positive number',
    onlyPositive: value => value > 0 || 'Should be positive number',
    noNegative: value => value >= 0 || 'Should be positive number or zero',
    required: value => !!value || 'Required', // Name is required
    titleRequired: value => !!value || 'Title required', // Name is required
    max160Characters: value => value.length <= 160 || "Max 160 characters",
}
