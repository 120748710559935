export default {

    SET_SOURCES_LIST(state, newValue) {
        state.sourcesList = newValue;
    },
    SET_SELECTED_SOURCE(state, val) {
        state.selectedSource = val;
    },
    SET_SELECTED_CONNECTOR(state, val) {
        state.selectedConnector = val;
    },
    SET_SOURCE_HISTORY(state, val) {
        state.selectedSourceHistory = val;
    },
    SET_SELECTED_SOURCE_SCHEDULE(state, val) {
        state.selectedSourceSchedule = val;
    },
    SET_LOADING_SOURCES_LIST(state, val) {
        state.loadingSourcesList = val;
    },
    SET_SELECTED_SOURCE_API_KEY(state, val) {
        state.selectedSourceApiKey = val;
    },
    SET_RELATED_COLLECTIONS_LIST(state, value) {
        state.relatedCollectionsList = value;
    },
    SET_RELATED_EVENTS_LIST(state, value) {
        state.relatedEventsList = value;
    },
    SET_RELATED_SEGMENTS_LIST(state, value) {
        state.relatedSegmentsList = value;
    },
    RESET_RELATED_SEGMENTS_LIST(state) {
        state.relatedSegmentsList = [];
    },
    SET_LOADING_RELATED_SEGMENTS_LIST(state, newValue) {
        state.loadingRelatedSegmentsList = newValue;
    },
    RESET_RELATED_METRICS_LIST(state) {
        state.relatedMetricsList = [];
    },
    SET_RELATED_METRICS_LIST(state, value) {
        state.relatedMetricsList = value;
    },
    SET_TEMPLATE_LIST(state, value) {
        state.templateList = value;
    },
    SET_LOADING_TEMPLATE_LIST(state, value) {
        state.loadingTemplateList = value;
    },
    SET_DISABLED_SOURCE(state, value) { // WHAT IS THIS ????
        const disabledSource = state.sourcesList.find(source => source.id === value.id);

        if (!disabledSource.read) {
            disabledSource.read = true;
            //   disabledSource.write = false
        } else {
            disabledSource.read = !disabledSource.read;
        }
    }

};