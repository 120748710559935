import { getInstance as authWrapper } from "../../../auth/authWrapper";
import axios from "../../../axios";
import stripe from "../../../stripe"
import store from "../../../store"
export default {
    createNewOrg({ commit, dispatch }, orgData) {
        return new Promise((resolve, reject) => {
            return axios
                .post("/organizations", orgData)
                .then(response => {
                    dispatch("fetchUserOrgs")
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "New organization created",
                    });
                    localStorage.setItem("currentSlide", 2);
                    window.location.reload();
                    resolve(response)
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    fetchUserOrgs({ commit, state, dispatch }) {
        commit("SET_LOADING_ORG_LIST", true);

        return new Promise((resolve, reject) => {
            let _orgsList = [];

            return axios
                .get(`/organizations`)
                .then(response => {
                    const { _embedded, _links } = response.data;
                    _orgsList = _embedded.organizations.filter((i) => i.title != "playground")
                    function fetchMore(nextLink) { // HELPER FUNCTION
                        return axios.get(nextLink)
                            .then((response) => {
                                const { _embedded, _links } = response.data;
                                _orgsList = _embedded.organizations.filter((i) => i.title != "playground")
                                if (_links.next?.href) {
                                    fetchMore(_links.next.href);
                                } else {
                                    commit("SET_ORG_LIST", _orgsList);
                                    commit("SET_LOADING_ORG_LIST", false);
                                    resolve(_orgsList);
                                }
                            });
                    }
                    if (_links.next?.href) { // _links.next?.href
                        fetchMore(_links.next.href);
                    } else {
                        commit("SET_ORG_LIST", []);
                        commit("SET_ORG_LIST", _orgsList);
                        commit("SET_LOADING_ORG_LIST", false);
                        resolve(_orgsList);
                    }
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    changeOrg({ commit, state }, orgName = state.selectedOrg && state.selectedOrg.name) {
        const _org = state.orgList.find((org) => org.name === orgName);
        if (!_org) return;
        commit("SET_SELECTED_ORG", _org);
    },

    updateOrg({ state, commit, dispatch }, newOrgData) {
        return new Promise((resolve, reject) => {
            return axios
                .put(`/organizations/${newOrgData.id}`, newOrgData)
                .then(response => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Organization details updated",
                    });
                    authWrapper()
                        .init()
                        .then(() => {
                            commit("SET_SELECTED_ORG", response.data);
                            resolve();
                        });
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        // SHOW NO ERROR
                    } else {
                        store.dispatch("global/addAlert", {
                            color: "error",
                            text: store.state.global.errorMessage,
                        });
                    }
                    reject(error);
                });
        });
    },

    deleteOrg({ state, commit, dispatch }, org) {
        return new Promise((resolve, reject) => {
            return axios
                .delete(`/organizations/` + org.id)
                .then(async () => {
                    await stripe.delete(`/customers/${org.billingId}`, {
                        headers: stripeAuthHeader,
                    }).then(() => {
                        commit("SET_SELECTED_ORG", state.orgList[state.orgList.length - 1]);
                    })
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Organization deleted",
                    });

                    authWrapper()
                        .init()
                        .then(() => {
                            resolve();
                        });
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        // SHOW NO ERROR
                    } else {
                        store.dispatch("global/addAlert", {
                            color: "error",
                            text: store.state.global.errorMessage,
                        });
                    }
                    reject(error);
                });
        });
    },

    checkOrgNameAvailability({ state, commit, dispatch }, name) {
        return new Promise((resolve, reject) => {
            return axios
                .post(`/organization-names/${name}/check`)
                .then(response => {
                    if (response.data.available) {
                        resolve();
                    } else {
                        reject(Error);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};