import { getInstance as authWrapper } from "../../../auth/authWrapper";

export default {
    init() { },

    isLoggedIn() {
        return new Promise((resolve) => {
            let isLoading = true;
            let elapsed = 0;
            const checkIfLoading = () => {
                isLoading = authWrapper().loading;
                if (isLoading && elapsed < 5000) {
                    elapsed += 200;
                } else {
                    const checkIntervalTimer = setInterval(checkIfLoading, 200);
                    clearInterval(checkIntervalTimer);
                    return resolve(authWrapper().isAuthenticated);
                }

                return undefined;
            };
            checkIfLoading();
        });
    },

    logOut({ commit }) {
        commit("SET_CURRENT_USER", null);
    },

    saveUserDetails({ commit }, payload) {
        commit("SET_USER_DETAILS", payload);
    },

    setToken({ commit }, payload) {
        commit("SET_TOKEN", payload);
    },

    getUserDetails() {
        // commit('SET_USER_DETAILS', payload)
    },
};