import axios from 'axios'

const baseUrl = 'https://api.stripe.com/v1'

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: process.env.VUE_APP_STRIPE_SK
  }
})

export default axiosInstance
