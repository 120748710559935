export default {
    SET_SELECTED_ORG(state, val) {
        state.selectedOrg = val;
    },
    SET_ORG_LIST(state, val) {
        state.orgList = val;
    },
    SET_LOADING_ORG_LIST(state, val) {
        state.loadingOrgList = val;
    },
};