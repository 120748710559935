<!-- eslint-disable -->
<template>
	<section class="templates">
		<!-- SHOPIFY HEADER SLOT -->
		<slot name="shopifyHeader"></slot>
		<!-- SHOPIFY HEADER SLOT -->

		<!-- TEMPLATE LIST -->
		<div class="templates__list">
			<div :class='["templates__list__item", template.selected && "selected"]'
			     v-for="template in templates"
			     @click='handleTemplateSelect({template1:template, templateType: templateType})'>
				<img class="templates__list__item__logo"
				     :src="selectedSource.iconPath"
				     :alt="selectedSource.title"/>
				<p class="templates__list__item__title">{{ template.title }}</p>
				<p class="templates__list__item__description">{{ template.description }}</p>
				<i class="templates__list__item__check fas fa-check-circle"
				   v-if="template.selected"></i>
			</div>
		</div>
		<!-- TEMPLATE LIST -->

		<!-- BUTTONS -->
		<div class="templates__bottom">
			<v-btn class="templates__bottom__button text-capitalize"
			       color="primary"
			       @click="$emit('handlePreviousStepClick')"
			       outlined>
				<img class="templates__bottom__button__icon"
				     style="margin-right: 15px;"
				     src="/img/general/long-arrow-left-blue.svg"
				     alt="image"/>
				Previous step
			</v-btn>

			<span class="templates__bottom__text"
			      @click="$emit('handleSkipStepClick')">Skip this step</span>

			<v-btn class="templates__bottom__button text-capitalize"
			       color="primary"
			       @click="$emit('handleNextStepClick')">
				Next step
				<img class="templates__bottom__button__icon"
				     style="margin-left: 15px;"
				     src="/img/general/long-arrow-right-white.svg"
				     alt="image"/>
			</v-btn>
		</div>
		<!-- BUTTONS -->
	</section>
</template>


<script>
/* eslint-disable */

export default {
	name: "Templates",
	props: {
		templates: {
			type: Array,
			default: () => [],
			required: true,
		},
	  templateType: {
		  type: String,
		  default: "",
		  required: true,
	  },
		selectedSource: {
			type: Object,
			default: () => {},
			required: true,
		},
	},
	components: {},
	data() {
		return {};
	},
	computed: {},
	methods: {
		handleTemplateSelect({ template1, templateType }) {
			this.$emit("handleTemplateSelect", { template1, templateType });
		},
	},
	mounted() {},
	created() {},
};
</script>


<style scoped lang="scss">
.templates {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	height: 100%;
	width: 1075px;

	.templates__header {
		border-radius: 5px;
		border: solid 1px #ced1d9;
		padding: 50px;
		margin: 0 50px 0 25px;
		background: #ffffff;

		.templates__header__shop {
			margin-bottom: 25px;

			.templates__header__shop__logo {
				margin-right: 15px;
			}

			.templates__header__shop__title {
				font-family: Muli, sans-serif;
				color: #137bf2;
				font-size: 16px;
				font-weight: 400;
			}
		}

		.templates__header__title {
			font-family: Muli, sans-serif;
			color: #282e3b;
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 25px;
		}

		.templates__header__description {
			font-family: Muli, sans-serif;
			color: #4f5a6f;
			font-size: 14px;
			font-weight: 400;
			margin: 0;
		}
	}

	.templates__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		max-height: 750px;
		overflow-y: scroll;


		.templates__list__item {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
			width: 300px;
			height: 300px;
			box-sizing: border-box;
			border: 1px solid #e4e5e8;
			border-radius: 5px;
			padding: 25px;
			position: relative;
			margin: 25px;
			cursor: pointer;
			background-color: white;

			.templates__list__item__logo {
				width: 42px;
				height: 48px;
				margin-bottom: 25px;
			}

			.templates__list__item__title {
				font-family: Muli, sans-serif;
				color: #033d80;
				font-size: 18px;
				font-weight: bold;
				align-self: flex-start;
				text-align: left;

			}

			.templates__list__item__description {
				font-family: Muli, sans-serif;
				color: rgba(79, 90, 111, 0.77);
				font-size: 14px;
				font-weight: 600;
				align-self: flex-start;
				text-align: left;
			}

			.templates__list__item__check {
				color: #4aba57;
				top: 25px;
				right: 25px;
				position: absolute;
				font-size: 20px;
			}
		}

		.selected {
			border: 1px solid #4aba57 !important;
		}
	}
}

::v-deep.templates__bottom {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;

	.templates__bottom__button {
		font-family: Muli, sans-serif;

		.templates__bottom__button__icon {
			width: 15px;
		}
	}

	.templates__bottom__text {
		margin-left: auto;
		margin-right: 25px;
		cursor: pointer;
		color: #137bf2;
		font-family: Muli, sans-serif;
		font-size: 14px;
		font-weight: 600;
	}
}
</style>
