import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Auth0Plugin } from "./auth";

Vue.config.productionTip = false

const auth0domain = process.env.VUE_APP_AUTH0_DOMAIN;
const auth0clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

Vue.use(Auth0Plugin, {
  domain: auth0domain,
  clientId: auth0clientId,
  onRedirectCallback: (appState) => {
    router.push({ path: "/", query: { shopname: localStorage.getItem("shopname") } });
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
