import { getInstance as authWrapper } from "../../../auth/authWrapper";
import axios from "../../../axios";
import stripe from "../../../stripe"
import router from "../../../router";
import store from "../../../store"
export default {
    fetchSourcesList({ commit, rootState, dispatch }) {
        commit("SET_LOADING_SOURCES_LIST", true);
        // commit("SET_SOURCES_LIST", []);

        return new Promise((resolve, reject) => {
            const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
            if (!curOrgName) return undefined;
            if (!axios.defaults.headers.common.Authorization) return undefined;

            return axios
                .get(`/${curOrgName}/sources`)
                .then(response => {
                    const { sources } = response.data._embedded;
                    commit("SET_SOURCES_LIST", sources);
                    commit("SET_LOADING_SOURCES_LIST", false);
                    resolve(sources);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    fetchTemplates({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING_TEMPLATE_LIST", true);
            const _templateList = [];

            return axios
                .get(`/templates`)
                .then(response => {
                    const { _embedded, _links } = response.data;
                    _templateList.push(..._embedded.templateList);

                    function fetchMore(nextLink) { // HELPER FUNCTION
                        return axios.get(nextLink)
                            .then((response) => {
                                const { _embedded, _links } = response.data;
                                _templateList.push(..._embedded.templateList);

                                if (_links.next?.href) {
                                    fetchMore(_links.next.href);
                                } else {
                                    commit("SET_TEMPLATE_LIST", _templateList);
                                    commit("SET_LOADING_TEMPLATE_LIST", false);
                                    _templateList.forEach(_template => _template.selected = false);
                                    resolve(_templateList);
                                }
                            });
                    }

                    if (_links.next?.href) { // _links.next?.href
                        fetchMore(_links.next.href);
                    } else {
                        commit("SET_TEMPLATE_LIST", _templateList);
                        commit("SET_LOADING_TEMPLATE_LIST", false);
                        _templateList.forEach(_template => _template.selected = false);
                        resolve(_templateList);
                    }
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    createTemplates({ commit, rootState, dispatch }, payload) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;
        console.log("currentOrgName", currentOrgName)
        console.log("payload", payload)
        console.log("axios.defaults.headers.common.Authorization", axios.defaults.headers.common.Authorization)

        return new Promise((resolve, reject) => {
            commit("SET_LOADING_CREATE_TEMPLATES", true);

            return axios
                .post(`/${curOrgName}/template-executions`, payload)
                .then(response => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Templates are created",
                    });
                    commit("SET_LOADING_CREATE_TEMPLATES", false);
                    resolve(response);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    switchCurrentSource({ state, commit, rootState, dispatch }, id) {

        return new Promise((resolve, reject) => {
            const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
            if (!curOrgName) return undefined;
            if (!axios.defaults.headers.common.Authorization) return undefined;

            return axios
                .get(`/${curOrgName}/sources/` + id)
                .then(response => {
                    commit("SET_SELECTED_SOURCE", response.data);

                    if (response.data.integrationId) {
                        dispatch("getConnector", { id: response.data.integrationId, authorization: false });
                    } else {
                        commit("SET_SELECTED_CONNECTOR", null);
                    }

                    resolve(response.data);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    updateSource({ state, commit, rootState, dispatch }, sourceData) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;

        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;
        const fullSourceData = Object.assign({ ...state.selectedSource }, sourceData);

        return new Promise((resolve, reject) => {
            return axios
                .put(`/${curOrgName}/sources/${fullSourceData.id}`, fullSourceData)
                .then((response) => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Source details updated",
                    });
                    dispatch("switchCurrentSource", response.data.id);
                    resolve();
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    deleteSource({ state, dispatch, rootState }) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .delete(`/${curOrgName}/sources/${state.selectedSource.id}`)
                .then(() => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Source deleted",
                    });
                    router.push({ name: "sources-list" });
                    dispatch("fetchSourcesList");
                    resolve();
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    fetchSourceApiKey({ commit, state, rootState, dispatch }, sourceId = state.selectedSource && state.selectedSource.id) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!sourceId) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get(`/${curOrgName}/sources/${sourceId}/api-keys/current`)
                .then((response) => {
                    commit("SET_SELECTED_SOURCE_API_KEY", response.data.apiKey);
                    resolve(response.data.apiKey);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    createNewSource({ rootState, dispatch }, { sourceObj, isOnboarding = false }) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .post(`/${curOrgName}/sources`, sourceObj)
                .then(response => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "New source created",
                    });
                    localStorage.removeItem("connectorUrl");

                    // if (!isOnboarding) {
                    //     dispatch("fetchSourcesList")
                    //         .then(() => dispatch("switchCurrentSource", response.data.id));
                    // } else {
                    //     dispatch("fetchSourcesList");
                    // }

                    resolve(response.data);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    checkSourceNameAvailability({ rootState, dispatch, commit }, name) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get("/" + currentOrgName + "/sources?name=" + name)
                .then(response => {
                    const { sources } = response.data._embedded;
                    console.log(sources)
                    if (sources.length > 0) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        // SHOW NO ERROR
                    } else {
                    }
                    reject(error);
                });
        });
    },

    // CONNECTOR

    getIntegrationData({ rootState, dispatch, commit }, sessionId) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;
        const axiosConfig = {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                sessionId: sessionId
            }
        };

        return new Promise((resolve, reject) => {
            return axios
                .post("/" + currentOrgName + "/integrations?sessionId=" + sessionId)
                .then(response => {
                    const { accessToken, scope, shopname } = response.data;

                    if (accessToken && scope && shopname) {
                        axios
                            .post("/" + currentOrgName + "/integrations", {
                                type: "shopify"
                            }, axiosConfig)
                            .then(response => {
                                const connectorId = response.headers.location.split("/").pop();
                                dispatch("getConnector", { id: connectorId, authorization: true });
                                localStorage.setItem("connectorId", connectorId);
                                resolve(connectorId);
                            });
                    }

                    resolve(response);
                })
                .catch(error => {
                    commit("SET_SELECTED_CONNECTOR", null);
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    createNewConnector({ rootState, dispatch }, newConnectorObject) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            const axiosConfig = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            return axios
                .post("/" + currentOrgName + "/integrations/", newConnectorObject, axiosConfig)
                .then(response => {
                    const connectorId = response.headers.location.split("/").pop();
                    dispatch("getConnector", { id: connectorId, authorization: true });
                    localStorage.setItem("connectorId", connectorId);
                    resolve(connectorId);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    updateConnector({ dispatch, rootState }, payloadObject) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        const axiosConfig = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { connectorId } = payloadObject;
        const { updatedConnectorObject } = payloadObject;

        return new Promise((resolve, reject) => {
            return axios
                .put(`/${currentOrgName}/integrations/${connectorId}`, updatedConnectorObject, axiosConfig)
                .then((response) => {
                    dispatch("getConnector", { id: connectorId, authorization: true });
                    localStorage.setItem("connectorId", connectorId);
                    resolve(connectorId);
                })
                .catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    getConnector({ rootState, dispatch, commit }, { id, authorization }) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get(`/${currentOrgName}/integrations/` + id)
                .then(response => {
                    commit("SET_SELECTED_CONNECTOR", response.data);
                    if (authorization) {
                        // dispatch("authorizeConnector", id);
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("SET_SELECTED_CONNECTOR", null);
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    authorizeConnector({ rootState, dispatch }, connectorId) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .post(`/${currentOrgName}/integrations/` + connectorId + `/oauth`, {
                    "type": "authorize",
                })
                .then(response => {
                    const { authorizeUri } = response.data;
                    window.open(authorizeUri, "_blank", "width=500, height=500");
                    resolve(authorizeUri);
                })
                .catch(error => {
                    // if (error.response.status === 403) {
                    // 	// SHOW NO ERROR
                    // } else {
                    // 	store.dispatch("global/addAlert", {
                    // 		color: "error",
                    // 		text: store.state.global.errorMessage,
                    // 	});
                    // }
                    reject(error);
                });
        });
    },

    handleConnectorCallback({ rootState, dispatch, commit }, args) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        // if (!axios.defaults.headers.common.Authorization) return undefined

        return new Promise((resolve, reject) => {
            return axios
                .post(`/${currentOrgName}/integrations/` + args.connectorId + `/oauth`, {
                    "type": "callback",
                    "code": args.code,
                }).then(response => {
                    localStorage.removeItem("connectorUrl");
                    resolve(response);
                }).catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    // CONNECTOR


    // SCHEDULE

    createSourceSchedule({ rootState, dispatch }, payload) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        console.log("currentOrgName", currentOrgName)
        console.log("payload", payload)
        console.log("axios.defaults.headers.common.Authorization", axios.defaults.headers.common.Authorization)
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .post(`/${curOrgName}/sources/` + payload.sourceId + "/schedule", payload.scheduleObject)
                .then(response => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "New source schedule created",
                    });
                    resolve(response.data);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    getSourceSchedule({ rootState, dispatch, commit }, sourceId) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get(`/${curOrgName}/sources/` + sourceId + "/schedule")
                .then(response => {
                    commit("SET_SELECTED_SOURCE_SCHEDULE", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    // if (error.response.status === 403) {
                    //   // SHOW NO ERROR
                    // } else {
                    //   store.dispatch('global/addAlert', {
                    //     color: 'error',
                    //     text: store.state.global.errorMessage,
                    //   })
                    // }
                    commit("SET_SELECTED_SOURCE_SCHEDULE", null);
                    reject(error);
                });
        });
    },

    updateSourceSchedule({ rootState, dispatch, commit }, payload) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .put(`/${curOrgName}/sources/` + payload.sourceId + "/schedule", payload.scheduleObject)
                .then(response => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Source schedule updated",
                    });
                    commit("SET_SELECTED_SOURCE_SCHEDULE", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    deleteSourceSchedule({ rootState, dispatch, commit }, sourceId) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .delete(`/${curOrgName}/sources/` + sourceId + "schedule")
                .then(response => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Source schedule deleted",
                    });
                    commit("SET_SELECTED_SOURCE_SCHEDULE", null);
                    resolve(response.data);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    getSourceHistory({ rootState, dispatch, commit }, sourceId) {
        const curOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!curOrgName) return undefined;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get(`/${curOrgName}/sources/` + sourceId + "/history")
                .then(response => {
                    const { historyRowList } = response.data._embedded;
                    commit("SET_SOURCE_HISTORY", historyRowList);
                    resolve(historyRowList);
                })
                .catch(error => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    // SCHEDULE


    getS3BucketLink({ rootState, dispatch, commit }, { collectionId, file }) { // ONLY FOR DISPLAY PURPOSES
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;
        axios.defaults.headers.post["Content-Type"] = "text/csv";
        const axiosConfig = {
            headers: {
                "Content-Type": "text/csv",
            },
            transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data;
            }]
        };

        let s3BucketLink;

        return new Promise((resolve, reject) => {
            return axios
                .put("/" + currentOrgName + "/collections/" + collectionId + "/data", axiosConfig)
                .then((response) => {
                    s3BucketLink = response.headers.location;
                    // axios.defaults.headers.post["Content-Type"] = "application/json";
                    dispatch("postToS3BucketLink", { link: s3BucketLink, file: file });
                    resolve(s3BucketLink);
                }).catch((error) => {
                    s3BucketLink = response.headers.location;
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    postToS3BucketLink({ rootState, dispatch, commit }, { link, file }) { // ONLY FOR DISPLAY PURPOSES
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;
        axios.defaults.headers.post["Content-Type"] = "text/csv";
        const axiosConfig = {
            headers: {
                "Content-Type": "text/csv",
            },
            baseURL: "",
            transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data;
            }]
        };

        return new Promise((resolve, reject) => {
            return axios
                .put(link, file, axiosConfig)
                .then((response) => {
                    axios.defaults.headers.post["Content-Type"] = "application/json";
                    resolve();
                }).catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    // DELETION

    getRelatedCollections({ rootState, dispatch, commit }, sourceId) { // ONLY FOR DISPLAY PURPOSES
        commit("SET_RELATED_COLLECTIONS_LIST", []);
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get("/" + currentOrgName + "/collections?sourceId=" + sourceId)
                .then((response) => {
                    const { collections } = response.data._embedded;
                    commit("SET_RELATED_COLLECTIONS_LIST", collections);
                    resolve(collections);
                }).catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    getRelatedEvents({ rootState, dispatch, commit }, sourceId) {
        commit("SET_RELATED_EVENTS_LIST", []);
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get("/" + currentOrgName + "/events?sourceId=" + sourceId)
                .then((response) => {
                    const { events } = response.data._embedded;
                    commit("SET_RELATED_EVENTS_LIST", events);
                    resolve(events);
                }).catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    getRelatedSegments({ rootState, dispatch, commit }, sourceId) {
        commit("SET_RELATED_SEGMENTS_LIST", []);
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get("/" + currentOrgName + "/segments?sourceId=" + sourceId)
                .then((response) => {
                    const { segments } = response.data._embedded;
                    commit("SET_RELATED_SEGMENTS_LIST", segments);
                    resolve(segments);
                }).catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    getRelatedMetrics({ rootState, dispatch, commit, state }, sourceId) { // WORKS
        commit("SET_RELATED_METRICS_LIST", []);
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .get("/" + currentOrgName + "/metrics?sourceId=" + sourceId)
                .then((response) => {
                    const { metrics } = response.data._embedded;
                    commit("SET_RELATED_METRICS_LIST", metrics);
                    resolve(metrics);
                })
                .catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    deleteRelatedCollections({ rootState, dispatch, commit }, sourceId) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .delete("/" + currentOrgName + "/collections?sourceId=" + sourceId)
                .then((response) => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Related collections(s) deleted",
                    });
                    resolve(response);
                })
                .catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    deleteRelatedEvents({ rootState, dispatch, commit, state }, sourceId) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios
                .delete("/" + currentOrgName + "/events?sourceId=" + sourceId)
                .then((response) => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Related event(s) deleted",
                    });
                    resolve(response);
                })
                .catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    deleteRelatedSegments({ rootState, dispatch, commit, state }, sourceId) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios.delete("/" + currentOrgName + "/segments?sourceId=" + sourceId)
                .then((response) => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Related segment(s) deleted",
                    });
                    resolve(response);
                })
                .catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    deleteRelatedMetrics({ rootState, dispatch, commit, state }, sourceId) {
        const currentOrgName = rootState.org.selectedOrg && rootState.org.selectedOrg.name;
        if (!currentOrgName) return;
        if (!axios.defaults.headers.common.Authorization) return undefined;

        return new Promise((resolve, reject) => {
            return axios.delete("/" + currentOrgName + "/metrics?sourceId=" + sourceId)
                .then((response) => {
                    store.dispatch("global/addAlert", {
                        color: "success",
                        text: "Related metric(s) deleted",
                    });
                    resolve(response);
                })
                .catch((error) => {
                    store.dispatch("global/addAlert", {
                        color: "error",
                        text: store.state.global.errorMessage,
                    });
                    reject(error);
                });
        });
    },

    // DELETION
};
