<!-- eslint-disable -->
<template>
  <section :class="insideAnotherPage ? 'insideAnotherPage' : 'fullPage'">
    <!-- TOP NAV BAR -->
    <div class="topNavBar" v-if="!insideAnotherPage">
      <img
        class="topNavBar__logo"
        style="height: 25px"
        src="/img/sources/intemptLogo.svg"
        alt="intemptLogo"
      />
      <!-- <UserAvatar :auth="$auth" screen-type="organizationSelection" /> -->
    </div>
    <!-- TOP NAV BAR -->

    <!-- SELECT ORGANIZATION -->
    <div class="organizationSelection__inner" v-if="orgList.length > 0">
      <p class="organizationSelection__inner__title" v-if="!insideAnotherPage">
        Select organization
      </p>
      <p class="organizationSelection__inner__text" v-if="!insideAnotherPage">
        Organizations let you collaborate with team members, add permissions and
        share sources across your whole team under a shared billing account.
      </p>

      <!-- SHOPIFY AUTHORIZATION SLOT -->
      <slot name="shopifySelectOrg"></slot>
      <!-- SHOPIFY AUTHORIZATION SLOT -->

      <v-list>
        <v-list-item
          @click="handleSelectOrganization(org)"
          v-for="(org, i) in orgList"
          :key="i"
        >
          <img
            class="list__item__icon1"
            src="/img/organization.svg"
            alt="image"
          />
          <span class="list__item__text">{{ org.title }}</span>
          <i class="list__item__icon2 fas fa-arrow-right"></i>
        </v-list-item>
      </v-list>

      <v-btn
        class="organizationSelection__inner__button text-capitalize"
        color="primary"
        block
        @click="showNewOrganizationDialog = true"
      >
        New organization
      </v-btn>
    </div>
    <!-- SELECT ORGANIZATION -->

    <!-- CREATE ORGANIZATION -->
    <div
      class="organizationSelection__inner"
      v-if="orgList.length === 0 && !$auth.loading"
    >
      <p class="organizationSelection__inner__title" v-if="!insideAnotherPage">
        Create organization
      </p>
      <p class="organizationSelection__inner__text" v-if="!insideAnotherPage">
        Organizations let you collaborate with team members, add permissions and
        share sources across your whole team under a shared billing account.
      </p>

      <!-- SHOPIFY AUTHORIZATION SLOT -->
      <slot name="shopifyNewOrg"></slot>
      <!-- SHOPIFY AUTHORIZATION SLOT -->

      <div class="organizationSelection__inner__input">
        <p class="organizationSelection__inner__input__title">
          Organization name
        </p>
        <v-text-field
          class="organizationSelection__inner__input__field"
          v-model="newOrg.title"
          background-color="white"
          :rules="[rules.required]"
          :error-messages="errorMessages.orgTitle"
          :loading="loadingTitleCheck"
          @input="checkOrgNameAvailability"
          height="36"
          dense
          outlined
        />
      </div>

      <v-btn
        class="organizationSelection__inner__button text-capitalize"
        color="primary"
        :disabled="!newOrg.title || !isValidName || loadingTitleCheck"
        block
        @click="handleCreateOrganization"
      >
        Create organization
      </v-btn>
    </div>
    <!-- CREATE ORGANIZATION -->

    <!-- NEW ORG POP-UP -->
    <v-dialog
      v-model="showNewOrganizationDialog"
      scrollable
      width="500"
      max-width="800"
    >
      <section class="newOrganizationDialog">
        <div class="newOrganizationDialog__header">
          <span class="newOrganizationDialog__header__title"
            >Create organization</span
          >
          <i
            class="newOrganizationDialog__header__icon fas fa-times"
            @click="showNewOrganizationDialog = false"
          ></i>
        </div>

        <v-divider></v-divider>

        <div class="newOrganizationDialog__body">
          <p class="newOrganizationDialog__body__title">Organization name</p>
          <v-text-field
            class="newOrganizationDialog__body__field"
            v-model="newOrg.title"
            background-color="white"
            :rules="[rules.required]"
            :loading="loadingTitleCheck"
            :error-messages="errorMessages.orgTitle"
            height="36"
            @input="checkOrgNameAvailability"
            dense
            outlined
          />
        </div>

        <div class="newOrganizationDialog__footer">
          <v-btn
            class="newOrganizationDialog__footer__button text-capitalize"
            color="primary"
            text
            @click="showNewOrganizationDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            class="newOrganizationDialog__footer__button text-capitalize"
            style="margin-left: 25px"
            color="primary"
            :disabled="!newOrg.title || !isValidName || loadingTitleCheck"
            @click="handleCreateOrganization"
          >
            Save
          </v-btn>
        </div>
      </section>
    </v-dialog>
    <!-- NEW ORG POP-UP -->
  </section>
</template>


<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import rules from "../../../../utils/rules";
// import UserAvatar from "@components/intempt/TopNav/UserAvatar.vue";
import slugify from "slugify";
import axios from "axios";
import qs from "querystring";
import _ from "lodash";
import store from "../../../../store";
const stripeCustomers = "https://api.stripe.com/v1/customers";
const stripeSubscriptions = "https://api.stripe.com/v1/subscriptions";
const stripeAuthHeader = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: process.env.VUE_APP_STRIPE_SK,
};
const stripeProPlanId = process.env.VUE_APP_STRIPE_PRO_PLAN;
const stripePlanId = process.env.VUE_APP_STRIPE_FREE_PLAN_ID;
const freePlan = process.env.VUE_APP_FREE_PLAN;

export default {
  name: "OrganizationSelection",
  props: {
    insideAnotherPage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  //   components: { UserAvatar },
  data() {
    return {
      rules,
      loadingTitleCheck: false,
      showNewOrganizationDialog: false,
      errorMessages: {
        orgTitle: [],
      },
      newOrg: {
        title: "",
        description: "",
        orgUrl: "",
        privacy: "public",
        billingEmailId: "",
        billingId: "",
      },
    };
  },
  computed: {
    ...mapGetters({ orgList: "org/getUserOrgs" }),
    isValidName() {
      return this.errorMessages.orgTitle.length === 0;
    },
  },
  methods: {
    handleSelectOrganization(selectedOrg) {
      this.$store.dispatch("org/changeOrg", selectedOrg.name);
      this.$emit("proceedToNextStep");
      this.$emit("changeOrg", selectedOrg.name);
    },

    checkOrgNameAvailability: _.debounce(function (newName) {
      const _newName = slugify(newName).toLowerCase();
      this.loadingTitleCheck = true;

      if (!newName) {
        this.loadingTitleCheck = false;
        this.errorMessages.orgTitle = [];
      }

      this.$store
        .dispatch("org/checkOrgNameAvailability", _newName)
        .then(() => {
          this.errorMessages.orgTitle = [];
        })
        .catch(() => {
          this.errorMessages.orgTitle.push("This name is already taken");
        })
        .finally(() => {
          this.loadingTitleCheck = false;
        });
    }, 250),

    handleCreateOrganization() {
      this.errorMessages.orgTitle = [];
      const customerData = {
        name: this.$auth.user && this.$auth.user.name,
        description: this.newOrg.title,
        email: this.$auth.user && this.$auth.user.email,
        "expand[]": "tax",
      };

      axios
        .post(stripeCustomers, qs.stringify(customerData), {
          headers: stripeAuthHeader,
        })
        .then(async (response) => {
          const _customerId = response.data.id;
          this.newOrg.billingEmailId = this.$auth.user.email;
          this.newOrg.billingId = _customerId;
          this.newOrg.title;
          const subscriptionData = {
            customer: _customerId,
            "items[0][price]": freePlan,
          };

          await axios
            .post(stripeSubscriptions, qs.stringify(subscriptionData), {
              headers: stripeAuthHeader,
            })
            .then(() => {
              this.$store
                .dispatch("org/createNewOrg", this.newOrg)
                .then(() => {
                  this.showNewOrganizationDialog = false;
                })
                .catch(() => {
                  this.showNewOrganizationDialog = false;
                  return axios
                    .delete(`${stripeCustomers}/${_customerId}`, {
                      headers: stripeAuthHeader,
                    })
                    .then(() => {});
                });
            });
        });
    },
  },
};
</script>


<style scoped lang="scss">
::v-deep .v-list {
  background-color: transparent;
  width: 100%;
  padding: 0 25px 0 0;
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 50px;
}

::v-deep .v-list-item {
  margin-bottom: 25px;
  background-color: white;
  border: solid 1px #a6aab5;
  border-radius: 4px;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .list__item__icon1 {
    margin-right: 25px;
  }

  .list__item__text {
    color: #1f2229;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .list__item__icon2 {
    margin-left: auto;
    font-size: 16px;
    color: #5b6579;
  }
}

.fullPage {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.insideAnotherPage {
}

::v-deep.topNavBar {
  width: 100%;
  border-bottom: solid 1px #e4e5e8;
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

::v-deep.organizationSelection__inner {
  width: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .organizationSelection__inner__title {
    color: #282e3b;
    font-family: Muli, sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .organizationSelection__inner__text {
    color: #4f5a6f;
    font-family: Muli, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  .organizationSelection__inner__input {
    width: 100%;
    margin: 50px 0;

    .organizationSelection__inner__input__title {
      color: #033d80;
      font-family: Muli, sans-serif;
      font-size: 12px;
      font-weight: 600;
    }

    .organizationSelection__inner__input__field {
      color: #1f2229;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .organizationSelection__inner__button {
  }
}

::v-deep.newOrganizationDialog {
  background-color: white !important;
  padding: 25px;

  .newOrganizationDialog__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    .newOrganizationDialog__header__title {
      color: #4f5a6f;
      font-family: Muli, sans-serif;
      font-size: 18px;
      font-weight: 600;
    }

    .newOrganizationDialog__header__icon {
      cursor: pointer;
    }
  }

  .newOrganizationDialog__body {
    margin-bottom: 25px;

    .newOrganizationDialog__body__title {
      color: #033d80;
      font-family: Muli, sans-serif;
      font-size: 12px;
      font-weight: 600;
    }

    .newOrganizationDialog__body__field {
      color: #1f2229;
      font-family: Muli, sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .newOrganizationDialog__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start;

    .newOrganizationDialog__footer__button {
      font-family: Muli, sans-serif;
    }
  }
}
</style>
