<template>
  <v-app>
    <div class="alertContainer">
      <v-alert
        class="alertContainer__alert"
        transition="scale-transition"
        dismissible
        elevation="24"
        :type="alert.color"
        v-for="(alert, index) in alerts"
        :key="index"
        :value="true"
        :index="index"
      >
        {{ alert.text }}
      </v-alert>
    </div>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {},
  computed: {
    alerts() {
      return this.$store.state.global.alerts;
    },
  },
  mounted() {
    if (!this.$route.query.shopname) {
      this.$router.push({
        path: "/",
        query: { shopname: localStorage.getItem("shopname") },
      });
    }
    
  },
};
</script>
<style lang="scss">
body {
  font-family: Muli, sans-serif !important;
}
.alertContainer {
  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 6;
  width: 500px;

  .alertContainer__alert {
    font-family: Muli, sans-serif;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}
</style>
