import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth/index"
import org from "./modules/org"
import sources from "./modules/sources"
import global from "./modules/global"
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        org,
        sources,
        global
    },
    getters: {

    },
    plugins: [
        createPersistedState({
            paths: ['auth', 'org', 'sources']
        }),
    ],
    strict: process.env.NODE_ENV !== 'production',
})
