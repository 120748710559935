import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import store from "../store"
import axios from "../axios"
import router from "../router/index"
/**
 *  Vue.js Instance Definition
 */

let instance;

export const getInstance = () => instance;

/**
 *  Vue.js Instance Initialization
 */

export const useAuth0 = ({
	onRedirectCallback = () =>
		window.history.replaceState({}, document.title, window.location.pathname),
	redirectUri = window.location.href,
	...pluginOptions
}) => {
	if (instance) return instance;

	instance = new Vue({
		data() {
			return {
				auth0Client: null,
				isLoading: true,
				isAuthenticated: false,
				user: {},
				error: null,
			};
		},
		methods: {
			async handleRedirectCallback() {
				this.isLoading = true;
				try {
					await this.auth0Client.handleRedirectCallback();
					this.user = await this.auth0Client.getUser();
					this.isAuthenticated = true;
				} catch (error) {
					this.error = error;
				} finally {
					this.isLoading = false;
				}
			},
			loginWithRedirect(options) {
				return this.auth0Client.loginWithRedirect(options);
			},
			logout(options) {
				return this.auth0Client.logout(options);
			},
			getTokenSilently(o) {
				return this.auth0Client.getTokenSilently(o);
			},
		},

		async created() {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			if (params.shopname) {
				localStorage.setItem("shopname", params.shopname)
			}
			if (params.sessionId) {
				localStorage.setItem("sessionId", params.sessionId)
			}
			this.auth0Client = await createAuth0Client({
				...pluginOptions,
				domain: pluginOptions.domain,
				client_id: pluginOptions.clientId,
				audience: "https://api.intempt.com",
				redirect_uri: redirectUri,
			});
			try {
				if (
					window.location.search.includes('code=') &&
					window.location.search.includes('state=')
				) {
					const { appState } = await this.auth0Client.handleRedirectCallback();

					onRedirectCallback(appState);
				}
				await this.auth0Client.getTokenSilently().then((token) => {
					axios.defaults.headers.common.Authorization = `Bearer ${token}`;
					store.dispatch("auth/setToken", token);
					store.dispatch("org/fetchUserOrgs")
				}).catch(async (err) => {
					axios.defaults.headers.common.Authorization = "";
				})
			} catch (error) {
				this.error = error;
			} finally {
				this.isAuthenticated = await this.auth0Client.isAuthenticated();
				this.user = await this.auth0Client.getUser();
				this.isLoading = false;
				if (!this.isAuthenticated) {
					this.auth0Client.loginWithRedirect()
				}
			}
		},
	});

	return instance;
};

/**
 *  Vue.js Plugin Definition
 */

export const Auth0Plugin = {
	install(Vue, options) {
		Vue.prototype.$auth = useAuth0(options);
	},
};