<!-- eslint-disable -->
<template>
	<section class="welcomeScreenContainer">
		<section class="welcomeScreenContainer__inner">
			<div class="welcomeScreenContainer__inner__header">
				<img class="welcomeScreenContainer__inner__header__logo" src="/img/shopifyAuthorization/site.svg" alt="image"/>
				<span class="welcomeScreenContainer__inner__header__title">{{$route.query.shopname}}.myshopify.com</span>
			</div>
			<p class="welcomeScreenContainer__inner__title">Welcome to Intempt app!</p>
			<p class="welcomeScreenContainer__inner__text">
				Grow your Customer LTV by automating highly personalized and engaging omnichannel customer experiences with Intempt.
				Connect your Shopify products, customers, orders, web behaviors, and events within Intempt so you can acquire, engage and retain more of your customers.
			</p>
			<img class="welcomeScreenContainer__inner__banner" src="/img/shopifyAuthorization/intemptBanner.svg" alt="image"/>
			<p class="welcomeScreenContainer__inner__title">Setup instructions</p>
			<p class="welcomeScreenContainer__inner__text">Let’s get started by following the steps:</p>
			<div class="welcomeScreenContainer__inner__horizontalLine"></div>
			<div class="welcomeScreenContainer__inner__row">
				<p class="welcomeScreenContainer__inner__row__count">Step 1</p>
				<div class="welcomeScreenContainer__inner__row__inner">
					<p class="welcomeScreenContainer__inner__row__inner__title">Create or Select an organization</p>
					<p class="welcomeScreenContainer__inner__row__inner__text">Organizations let you collaborate with team members, add permissions and share sources across your whole team under a shared billing account.</p>
				</div>
			</div>
			<div class="welcomeScreenContainer__inner__row">
				<p class="welcomeScreenContainer__inner__row__count">Step 2</p>
				<div class="welcomeScreenContainer__inner__row__inner">
					<p class="welcomeScreenContainer__inner__row__inner__title">Select metric templates</p>
					<p class="welcomeScreenContainer__inner__row__inner__text">Metric templates will accelerate your setup by creating Shopify metrics that you can access after you set up the Intempt Shopify app.</p>
				</div>
			</div>
			<div class="welcomeScreenContainer__inner__row">
				<p class="welcomeScreenContainer__inner__row__count">Step 3</p>
				<div class="welcomeScreenContainer__inner__row__inner">
					<p class="welcomeScreenContainer__inner__row__inner__title">Select segment templates</p>
					<p class="welcomeScreenContainer__inner__row__inner__text">Segment templates will accelerate your setup by creating Shopify segments that you can access after you set up the Intempt Shopify app.</p>
				</div>
			</div>
			<div class="welcomeScreenContainer__inner__row">
				<p class="welcomeScreenContainer__inner__row__count">Step 4</p>
				<div class="welcomeScreenContainer__inner__row__inner">
					<p class="welcomeScreenContainer__inner__row__inner__title">Select sync interval</p>
					<p class="welcomeScreenContainer__inner__row__inner__text">Choose the sync interval that Shopify data will be synced with Intempt.</p>
				</div>
			</div>
		</section>

		<v-btn class="welcomeScreenContainer__button text-capitalize"
		       color="primary"
		       @click="$emit('handleNextStepClick')">
			Next step
			<img class="welcomeScreenContainer__button__icon"
			     style="margin-left: 15px;"
			     src="/img/general/long-arrow-right-white.svg"
			     alt="image"/>
		</v-btn>
	</section>
</template>


<script>
/* eslint-disable */

export default {
	name: "WelcomeScreen",
	components: {},
	data() {
		return {};
	},
	computed: {},
	methods: {},
	mounted() {},
	created() {},
};
</script>


<style scoped lang="scss">
.welcomeScreenContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	height: 100%;
	margin-bottom: 50px;

	.welcomeScreenContainer__inner {
		background-color: #ffffff;
		border: 1px solid #ced1d9;
		border-radius: 5px;
		width: 975px;
		padding: 30px 50px;
		margin-bottom: 50px;
		height: 100%;


		.welcomeScreenContainer__inner__header {
			margin-bottom: 15px;

			.welcomeScreenContainer__inner__header__logo {
				margin-right: 15px;
			}

			.welcomeScreenContainer__inner__header__title {
				font-family: Muli, sans-serif;
				color: #137bf2;
				font-size: 16px;
				font-weight: 400;
			}
		}

		.welcomeScreenContainer__inner__title {
			font-family: Muli, sans-serif;
			color: #282e3b;
			font-weight: 600;
			font-size: 24px;
			margin: 0 0 25px 0;
		}

		.welcomeScreenContainer__inner__text {
			font-family: Muli, sans-serif;
			color: #4f5a6f;
			font-weight: 400;
			font-size: 14px;
			margin: 0 0 15px 0;
		}

		.welcomeScreenContainer__inner__banner {
			width: auto;
			height: 337px;
		}

		.welcomeScreenContainer__inner__horizontalLine {
			background: #E4E5E8;
			height: 1px;
			width: 100%;
			margin: 15px 0;
		}

		.welcomeScreenContainer__inner__row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 15px;
			width: 100%;

			.welcomeScreenContainer__inner__row__count {
				font-family: Muli, sans-serif;
				color: #282e3b;
				font-weight: 600;
				font-size: 18px;
				margin: 0;
				width: 75px;
			}

			.welcomeScreenContainer__inner__row__inner {
				width: 100%;

				.welcomeScreenContainer__inner__row__inner__title {
					font-family: Muli, sans-serif;
					color: #4f5a6f;
					font-weight: 600;
					font-size: 14px;
					margin: 0 0 15px 0;
				}

				.welcomeScreenContainer__inner__row__inner__text {
					font-family: Muli, sans-serif;
					color: #4f5a6f;
					font-weight: 400;
					font-size: 14px;
					margin: 0;
				}
			}
		}
	}

	.welcomeScreenContainer__button {
		font-family: Muli, sans-serif;

		.welcomeScreenContainer__button__icon {
			width: 15px;
		}
	}
}
</style>
